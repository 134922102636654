<template>
  <div>
    <div class="cui__utils__heading">
      <strong>{{ getTitle }}</strong>
    </div>
    <div class="card">
      <div class="card-header card-header-flex">
        <div class="d-flex flex-column justify-content-center mr-auto">
          <h5 class="mb-0">{{ news.ru ? news.ru.title : "Новая новость" }}</h5>
        </div>
      </div>
      <div class="card-body">
        <a-form-model ref="form" :model="news">
          <a-form-model-item
            class="text_area"
            label="Главное изображение"
            required
          >
            <a-upload
              :disabled="$route.query.is_preview"
              list-type="picture-card"
              :file-list="fileListMain"
              @change="handleChange"
            >
              <div v-if="fileListMain.length < 1 && !$route.query.is_preview">
                <a-icon type="plus" />
                <div class="ant-upload-text">Upload</div>
              </div>
            </a-upload>
          </a-form-model-item>
          <a-form-model-item label="Другие изображения" required>
            <a-upload
              :disabled="$route.query.is_preview"
              list-type="picture-card"
              :file-list="fileList"
              @change="handleChangeList"
            >
              <div v-if="fileList.length < 4 && !$route.query.is_preview">
                <a-icon type="plus" />
                <div class="ant-upload-text">Upload</div>
              </div>
            </a-upload>
          </a-form-model-item>
          <a-form-model-item label="Теги" required>
            <a-select
              v-model="news.tag_id"
              :disabled="$route.query.is_preview"
              mode="multiple"
              placeholder="выберите теги"
            >
              <a-select-option
                v-for="tag in tags"
                :key="tag.id"
                :value="tag.id"
              >
                {{ tag.ru.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="Статус" required>
            <a-select
              v-model="news.active"
              :disabled="$route.query.is_preview"
              placeholder="выберите статус"
            >
              <a-select-option
                v-for="type in required"
                :key="type.id"
                :value="type.id"
              >
                {{ type.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="Отображение на главной странице" required>
            <a-select
              v-model="news.is_home"
              :disabled="$route.query.is_preview"
              placeholder="выберите статус"
            >
              <a-select-option
                v-for="type in visibilityHome"
                :key="type.id"
                :value="type.id"
              >
                {{ type.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="Ссылка на другую новость" required>
            <a-input
              v-model="news.quote_url"
              :disabled="$route.query.is_preview"
            />
          </a-form-model-item>
          <a-form-model-item
            class="text_area"
            label="Изображение другой новости"
            required
          >
            <a-upload
              :disabled="$route.query.is_preview"
              list-type="picture-card"
              :file-list="fileListQuote"
              @change="handleChangeQuote"
            >
              <div v-if="fileListQuote.length < 1 && !$route.query.is_preview">
                <a-icon type="plus" />
                <div class="ant-upload-text">Upload</div>
              </div>
            </a-upload>
          </a-form-model-item>
          <a-form-model-item label="Заголовок(ua)" required>
            <span>Макс. кол-во символов - 240</span>
            <a-input
              v-model="news.ua.title"
              :disabled="$route.query.is_preview"
            />
          </a-form-model-item>
          <a-form-model-item label="Анонсовый текст(ua)" required>
            <a-input
              v-model="news.ua.preview"
              :disabled="$route.query.is_preview"
            />
          </a-form-model-item>
          <a-form-model-item label="Другая новость(ua)" required>
            <a-input
              v-model="news.ua.quote_title"
              :disabled="$route.query.is_preview"
            />
          </a-form-model-item>
          <a-form-model-item
            class="text_area"
            label="Первая часть(ua)"
            required
          >
            <quill-editor
              ref="quillEditor"
              v-model="news.ua.text_first"
              :disabled="$route.query.is_preview"
            ></quill-editor>
          </a-form-model-item>
          <a-form-model-item
            class="text_area"
            label="Вторая часть(ua)"
            required
          >
            <quill-editor
              ref="quillEditor"
              v-model="news.ua.text_second"
              :disabled="$route.query.is_preview"
            ></quill-editor>
          </a-form-model-item>

          <a-form-model-item label="Заголовок(ru)" required>
            <span>Макс. кол-во символов - 240</span>
            <a-input
              v-model="news.ru.title"
              :disabled="$route.query.is_preview"
            />
          </a-form-model-item>
          <a-form-model-item label="Анонсовый текст(ru)" required>
            <a-input
              v-model="news.ru.preview"
              :disabled="$route.query.is_preview"
            />
          </a-form-model-item>
          <a-form-model-item label="Другая новость(ru)" required>
            <a-input
              v-model="news.ru.quote_title"
              :disabled="$route.query.is_preview"
            />
          </a-form-model-item>
          <a-form-model-item
            class="text_area"
            label="Первая часть(ru)"
            required
          >
            <quill-editor
              ref="quillEditor"
              v-model="news.ru.text_first"
              :disabled="$route.query.is_preview"
            ></quill-editor>
          </a-form-model-item>
          <a-form-model-item
            class="text_area"
            label="Вторая часть(ru)"
            required
          >
            <quill-editor
              ref="quillEditor"
              v-model="news.ru.text_second"
              :disabled="$route.query.is_preview"
            ></quill-editor>
          </a-form-model-item>
        </a-form-model>
        <a-button
          v-if="!this.$route.query.is_preview"
          class="ml-auto d-block"
          type="primary"
          @click="submit"
          :disabled="isDisabled"
        >
          {{
            this.$route.params.id !== "0" ? "Сохранить изменения" : "Создать"
          }}
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { quillEditor, Quill } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
const AlignStyle = Quill.import('attributors/style/align')
Quill.register(AlignStyle, true)

export default {
  name: 'NewsOverview',

  data() {
    return {
      news: {
        ru: {},
        ua: {},
      },
      tags: [],
      fileListMain: [],
      fileList: [],
      fileListQuote: [],
      required: [
        {
          name: 'Активная',
          id: 1,
        },
        {
          name: 'Не активная',
          id: 0,
        },
      ],
      visibilityHome: [
        {
          name: 'Не отображать',
          id: 0,
        },
        {
          name: 'отображать',
          id: 1,
        },
      ],
    }
  },
  created() {
    this.getTags()
    if (this.$route.params.id !== '0') {
      this.getNewsInfo()
    } else {
      this.news = {
        active: '',
        is_home: '',
        quote_url: '',
        ru: {
          preview: '',
          quote_title: '',
          text_first: '',
          text_second: '',
          title: '',
        },
        tag_id: [],
        ua: {
          preview: '',
          quote_title: '',
          text_first: '',
          text_second: '',
          title: '',
        },
      }
    }
  },
  computed: {
    getTitle() {
      if (this.$route.params.id === '0') {
        return 'Добавление новости'
      } else if (
        this.$route.params.id !== '0' &&
        this.$route.query &&
        this.$route.query.is_preview
      ) {
        return 'Просмотр новости'
      } else if (
        this.$route.params.id !== '0' &&
        !this.$route.query.is_preview
      ) {
        return 'Редактирование новости'
      }
      return ''
    },
    isDisabled() {
      let isDisabled = false

      for (const key in this.news) {
        if (typeof this.news[key] !== 'object' && !`${this.news[key]}`) {
          isDisabled = true
        }

        if (
          typeof this.news[key] === 'object' &&
          !Array.isArray(this.news[key])
        ) {
          for (const itemKey in this.news[key]) {
            if (!`${this.news[key][itemKey]}`) isDisabled = true
          }
        }

        if (
          typeof this.news[key] === 'object' &&
          Array.isArray(this.news[key])
        ) {
          if (!this.news[key] || !this.news[key].length) isDisabled = true
        }
      }

      if (
        !this.fileListMain.length ||
        !this.fileList.length ||
        !this.fileListQuote.length
      ) {
        isDisabled = true
      }

      return isDisabled
    },
  },
  methods: {
    async getNewsInfo() {
      try {
        this.news = (
          await this.$services.get(`admin/news/${this.$route.params.id}`)
        ).data.data.value

        this.fileListMain.push({
          url: this.news.mainImage,
          uid: '-4',
          name: 'image.png',
          status: 'done',
        })

        this.news.images.forEach((image, index) => {
          this.fileList.push({
            url: image,
            uid: index,
            name: 'image.png',
            status: 'done',
          })
        })

        this.fileListQuote.push({
          url: this.news.quote_image,
          uid: '-4',
          name: 'image.png',
          status: 'done',
        })
      } catch (e) {
        console.log(e)
      }
    },
    async getTags() {
      try {
        this.tags = (
          await this.$services.get('admin/tags')
        ).data.data.values.data
      } catch (e) {
        console.log(e)
      }
    },
    handleChange({ fileList }) {
      fileList.forEach((el, key) => {
        fileList[key].status = 'done'
      })

      this.fileListMain = fileList
    },
    handleChangeList({ fileList }) {
      fileList.forEach((el, key) => {
        fileList[key].status = 'done'
      })

      this.fileList = fileList
    },
    handleChangeQuote({ fileList }) {
      fileList.forEach((el, key) => {
        fileList[key].status = 'done'
      })

      this.fileListQuote = fileList
    },
    async submit() {
      let payload = {}
      if (this.$route.params.id !== '0') {
        this.news.images = this.fileList.map((image) => {
          return image.thumbUrl || image.url
        })

        if (this.fileListMain[0].thumbUrl) {
          this.news.mainIcon = this.fileListMain[0].thumbUrl
        }

        if (this.fileListQuote[0].thumbUrl) {
          this.news.quote_icon = this.fileListQuote[0].thumbUrl
        }

        payload = { ...this.news }

        try {
          await this.$services.put(`admin/news/${this.news.id}`, payload)
          this.$router.push({ path: '/content/news' })
        } catch (e) {
          console.log(e)
        }
      } else {
        this.news.images = this.fileList.map((image) => {
          return image.thumbUrl
        })

        this.news.mainIcon = this.fileListMain[0].thumbUrl
        this.news.quote_icon = this.fileListQuote[0].thumbUrl

        payload = { ...this.news }

        try {
          console.log('payload', payload)
          await this.$services.post('admin/news', payload)
          this.$router.push({ path: '/content/news' })
        } catch (e) {
          console.log(e)
        }
      }
    },
  },
  components: { quillEditor },
}
</script>

<style scoped lang="scss">
.ant-form {
  ::v-deep .ant-form-item-label {
    height: fit-content;
    line-height: 120%;
    label {
      height: fit-content;
    }
  }
  .ant-input-disabled,
  .ant-select-disabled {
    background-color: #ffffff;
    color: #595c97;
    ::v-deep .ant-select-selection {
      background-color: #ffffff;
    }
  }
  ::v-deep .ant-form-item-control {
    line-height: 30px;
  }
}
</style>
